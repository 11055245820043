<template>
  <div>
    <hsPageHeader
      :title="$t('sparkmembers.access-rules.title')"
      :sub-title="$t('sparkmembers.access-rules.sub-title')"
      :back-text="$t('breadcrumb.ProductDashboard')"
      back-route="ProductDashboard"
    />
    <div class="m-5">
      <div class="row">
        <div class="col-12 col-lg-8 mb-4">
          <div class="bg-white rounded-lg p-5">
            <Form :form="product" />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="d-flex">
            <hs-icon icon="engine-warning" :size="36" />
            <div class="ml-3">
              <h5 class="font-weight-bold">{{ $t('sparkmembers.access-rules.column.title') }}</h5>
              <p class="mt-1 mb-0">{{ $t('sparkmembers.access-rules.column.text') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import Form from './components/Form';

export default {
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
  },
  components: { Form, hsPageHeader },
};
</script>
