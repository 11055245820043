<template>
  <b-form @submit.prevent="onSubmit">
    <div class="mb-4">
      <hs-radio v-model="form.available_time_type" name="availability" value="indeterminate">
        {{ $t('sparkmembers.access-rules.form.indeterminate.label') }}
      </hs-radio>
      <p class="font-size-sm mt-2 mb-0" v-html="$t('sparkmembers.access-rules.form.indeterminate.description')" />
    </div>
    <div class="mb-4">
      <hs-radio v-model="form.available_time_type" name="availability" value="time">
        {{ $t('sparkmembers.access-rules.form.time.label') }}
      </hs-radio>
      <hs-group class="w-25 mt-2">
        <hs-input
          min="0"
          type="number"
          :placeholder="$t('sparkmembers.access-rules.form.time.placeholder')"
          v-model="form.available_time_length"
          :disabled="form.available_time_type !== 'time'"
        />
      </hs-group>
      <p class="font-size-sm mt-2 mb-0" v-html="$t('sparkmembers.access-rules.form.time.description')" />
    </div>
    <div>
      <hs-radio v-model="form.available_time_type" name="availability" value="date">
        {{ $t('sparkmembers.access-rules.form.date.label') }}
      </hs-radio>
      <hs-group class="w-25 mt-2">
        <hs-date
          id="date"
          v-model="expireDate"
          :label-no-date-selected="$t('date.format')"
          :disabled="form.available_time_type !== 'date'"
          :min="new Date()"
          value-as-date
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </hs-group>
      <p class="font-size-sm mt-2 mb-0" v-html="$t('sparkmembers.access-rules.form.date.description')" />
    </div>
    <div class="d-flex w-100 justify-content-end mt-5">
      <hs-button type="submit" :variant="isSaving ? 'light' : 'primary'" :disabled="isSaving">
        <b-spinner small type="grow" v-if="isSaving" />
        {{ $t(`sparkmembers.access-rules.form.${isSaving ? 'saving' : 'save'}`) }}
      </hs-button>
    </div>
  </b-form>
</template>

<script>
import { analyticsService, productService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import dayjs from 'dayjs';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isSaving = true;

      productService
        .update({
          ...this.form,
          ...this.normalizeFields(),
          available_time_unit: 'day',
        })
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.access-rules.toast.success'));
          analyticsService.track({
            event: 'Access rule updated',
            props: {
              product_id: this.form.id,
              type: this.checkType(this.form.available_time_type),
            },
          });
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkmembers.access-rules.toast.error')))
        .finally(() => (this.isSaving = false));
    },
    normalizeFields() {
      const { available_time_type, available_time_length, expire_date } = this.form;
      const normalize = {
        available_time_length: available_time_type !== 'time' ? null : available_time_length,
        expire_date: available_time_type !== 'date' ? null : expire_date,
      };
      this.form.available_time_length = normalize.available_time_length;
      this.form.expire_date = normalize.expire_date;
      return normalize;
    },
    checkType(type) {
      const types = ['indeterminate', 'time', 'date'];
      const description = ['unlimited', 'relative_date', 'fixed_date'];
      return description[types.indexOf(type)];
    },
  },
  computed: {
    expireDate: {
      get() {
        return this.form.expire_date ? dayjs(this.form.expire_date).toDate() : null;
      },
      set(newDate) {
        this.form.expire_date = newDate.toISOString();
      },
    },
  },
};
</script>
